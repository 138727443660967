.result-section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0px 128px 16px 100px;
}

.result-heading {
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  align-items: center;
  color: #1b2130;
}

.result-buttons {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.result-output-cover {
  box-shadow: 0px 0px 10px 0px #0000001a;
  margin: 0px 128px 16px 100px;
  min-height: 888px;
}

.result-output {
  display: grid;
  grid-template-columns: 1.3fr 1.7fr; /* Unequal parts: left is 2/3, right is 1/3 */
  gap: 16px; /* Optional: space between the parts */
  height: 838px;
  padding: 24px;
}

.result-output-left {
  background-color: #f0f0f0;
  
  padding: 8px;
  position: relative;
}

.result-output-right {
  overflow: scroll;
  background: linear-gradient(
    90deg,
    rgba(246, 246, 246, 0.8) 0%,
    rgba(246, 246, 246, 0.8) 100%
  );
  padding: 8px;
  color: "#C66D00" 
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(27, 33, 48, 0) 64.46%, rgba(27, 33, 48, 0.75) 100%);
  pointer-events: none; /* Allows interactions with underlying elements */
  z-index: 1; /* Ensure it sits above the canvas/image */
}

.media-container {
  position: relative;
  width: 100%;
  height: 100%;
  
}

.pdf-canvas,
.jpeg-image {
  width: 100%;
  height: 100%;
}

.result-pdf-details {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 24px;
  bottom: 24px; /* Keep this to position it at the bottom */
  color: white; /* Assuming text should be visible over the image */
  /* background-color: rgba(0, 0, 0, 0.5); Optional: Add background for better readability */ 
  padding: 8px;
  border-radius: 4px;
  z-index: 2;
}

.result-pdf-details .span1 {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
}

.result-pdf-details .span2 {
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  line-height: 19.2px;
}

@media (max-width: 1000px) {
}

@media (max-width: 800px) {
  .result-heading {
    font-size: 30px;
    line-height: 36px;
  }
  .result-section {
    margin-left: 48px;
  }

  .result-output {
    grid-template-columns: 1fr; /* Change to single column */
    grid-template-rows: auto auto; /* Two rows */
    height: auto; /* Adjust height if needed */
  }

  .result-output-cover{
    margin: 24px;
    min-height: 555px;
  }

  .pdf-canvas,
  .jpeg-image {
    height: auto;
    width: 100%; /* Adjust width to fit smaller screens */
  }
}

@media (max-width: 400px) {
  .result-heading {
    font-size: 20px;
    line-height: 24px;
  }
  .result-section {
    margin: 24px;
  }

  
}
