.hamburger {
  display: flex;
  padding: 4px;
  margin-top: 30px;
  justify-content: flex-end;
  margin-right: 128px;
}

.span-backgrounds {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.6),
    rgba(209, 211, 218, 0.6),
    rgba(255, 255, 255, 0.6)
  );
  display: inline-block;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;
  justify-content: center;
}

.hero-text {
  padding-top: 50px;
  width: 800px;
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
}

.hero-faster {
  color: #303d88;
  font-style: italic;
}

.hero-list {
  display: grid;
  grid-template-columns: repeat(
    5,
    auto
  ); /* Four items in a row for larger screens */
  gap: 16px;
  align-items: flex-start;
  padding: 0px;
}

.hero-list-item1 {
  width: 128px;
  height: 24px;
  font-weight: 300;
  font-size: 20px;
  line-height: 120%;
  color: #6b6b6b;
  /* No need for grid-column, as it will follow the grid structure */
}

.hero-list-item2 {
  width: 76px;
  height: 17px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #303d88;
  /* No need for grid-column, as it will follow the grid structure */
}

.hero-list-item2-cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 10px;
  width: 96px;
  height: 28px;
  background: rgba(48, 61, 136, 0.05);
  border-radius: 500px;
}

@media (max-width: 767px) {
  .hero-list {
    grid-template-columns: repeat(
      3,
      auto
    ); /* Three items in a row for smaller screens */
  }
}

.upload-main {
  box-sizing: border-box;

  position: relative;
  margin: 80px 128px 24px 128px;
  height: 485px;
  background: rgba(255, 255, 255, 0.25);

  backdrop-filter: blur(100px);
  border-radius: 20px;
}
@media (min-width: 1200px){
  .background-div {
    position: absolute;
    width: 154.93px;
    height: 992px;
    background: #303d88;
    filter: blur(150px);
    transform: rotate(90.07deg);
    top: 50%; /* Adjust as needed */
    left: 50%; /* Adjust as needed */
    transform: translate(-50%, -50%) rotate(90.07deg); /* Center and rotate */
    z-index: -1; /* Ensure it is behind other content */
  }
}


.upload-sub {
  box-sizing: border-box;
  position: relative;
  top: 20px;
  margin: 20px;
  height: 440px;

  background: #ffffff;
  border: 1px dashed rgba(27, 33, 48, 0.5);
  border-radius: 10px;
}

.upload-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.highlight {
  background-color: #e2e2ff;
  border-color: #252e6a;
}

.upload-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit; /* Match the border radius */
  padding: 1px; /* Border thickness */
  background: linear-gradient(
    251.11deg,
    rgba(48, 61, 136, 0.2) 0%,
    rgba(48, 61, 136, 0.7) 50%,
    rgba(48, 61, 136, 0.2) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude; /* For Safari */
  -webkit-mask-composite: destination-out; /* For Chrome and others */
}

.upload-content-text {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 24px;
  align-items: flex-start;
  padding: 8px;
  margin-bottom: 16px;
  align-items: center;
}

.upload-content-text-span {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #1b2130;
}

.upload-content-text-or {
  font-weight: 300;
  font-size: 32px;

  line-height: 38.4px;
  color: #1b2130;
  opacity: 50%;
}

.upload-content-text-sub {
  font-weight: 300;
  font-size: 18px;
  line-height: 21.6px;
  align-items: center;
  color: #1b2130;
  opacity: 50%;
}

.progress-bar {
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: #f6f6f6cc;
  margin-top: 8px;
  padding: 20px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar-content {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.progress-bar-header {
  display: flex;
  justify-content: space-between;
}

.progress-bar-header .truncate {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #1b2130;
}

@media (max-width: 1000px) {
  .hamburger {
    margin-right: 48px;
  }
  .upload-content-text-span {
    font-size: 30px;
    line-height: 36px;
  }
  .upload-content-text-or {
    font-size: 24px;
  }
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
  .upload-content-text {
    grid-template-columns: 2fr;
    gap: 4px;
  }
}

@media (max-width: 800px) {
  .hero-content {
    gap: 24px;
  }
  .upload-content {
    min-width: 70%;
  }
  .hero-text {
    width: auto;
    margin: 24px;
    font-size: 48px;
    line-height: 56px;
  }
  .upload-main {
    margin: 48px;
    min-height: 400px;

    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    border-radius: 10px;
  }

  
  .hero-list-item1 {
    grid-column: 2 / -1; /* Span all columns to ensure it's always on the first line */
  }

  .hero-list-item2-cover {
    grid-column: 2; /* Span all columns to ensure it's always on the first line */
  }

  .upload-content-text-span {
    font-size: 30px;
    line-height: 36px;
    grid-column: 1;
    grid-row: auto;
  }
  .upload-content-text-or {
    font-size: 24px;
    grid-column: 1;
    grid-row: auto;
  }
}

@media (max-width: 400px) {
  .hero-text {
    width: auto;
    font-size: 32px;
    line-height: 38.4px;
  }
  .upload-content-text-span {
    font-size: 20px;
    line-height: 24px;
  }
  .upload-content-text-or {
    font-size: 20px;
  }
}
