.footer-container {
  display: grid;
  gap: 24px;
  margin: 48px 128px 48px 100px;
  padding: 24px;
  align-items: center;
}

.item {
  padding: 20px;
  background-color: lightblue;
  border: 1px solid #ccc;
}

/* For larger screens */
@media (min-width: 768px) {
  .footer-container {
    margin-top: 5%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin-left: 100px; /* Adjust if necessary */
    margin-right: 128px; /* Adjust if necessary */
  }

  .item-1 {
    grid-column: 1;
    grid-row: 1 / 3; /* Span across both rows */
  }

  .item-2 {
    grid-column: 2;
    grid-row: 1;
  }

  .item-3 {
    grid-column: 2;
    grid-row: 2;
  }
}
.span1 {
  font-weight: 400;
  font-size: 24px;
  line-height: 28.8px;
  color: #1b2130;
  text-align: center;
}

/* For smaller screens */
@media (max-width: 767px) {
  .container {
    margin:24px;
  }
  .footer-container {
    grid-template-columns: 1fr; /* Switch to single column */
    margin-left: auto; /* Center content on smaller screens */
    margin-right: auto; /* Center content on smaller screens */
    padding: 16px; /* Adjust padding to fit smaller screens */
  }

  .item-1,
  .item-2,
  .item-3 {
    grid-column: auto;
    grid-row: auto;
  }
  .span1{
    font-size: 20px;
    line-height: 24px;
  }
}

.hero-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Make responsive */
  gap: 16px;
  align-items: center;
  padding: 0px;
}

.hero-list-item1,
.hero-list-item2 {
  width: 128px;
  height: 24px;
  font-weight: 300;
  font-size: 20px;
  line-height: 120%;
  color: #6b6b6b;
  text-align: center; /* Adjust to align center */
}

.hero-list-item2 {
  width: 76px;
  height: 17px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #303d88;
}

.hero-list-item2-cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 96px;
  height: 28px;
  background: rgba(48, 61, 136, 0.05);
  border-radius: 500px;
}


